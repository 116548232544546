import React from "react";

const ScheduledCalls = () => {
    return (
        <div>
            <h3>
                Looks like you don't have any scheduled calls yet.
            </h3>
            <p>
                All scheduled calls with tourists will appear here.
            </p>
        </div>  
    )
}

export default ScheduledCalls;